<template>
  <v-container class="ma-0">
    <v-row class="mx-2 mt-3" v-if="messagesITNRW">
        <MessagesITNRW :messages="messagesITNRW"/>
    </v-row>
    <v-row v-if="messagesWithoutHint && messagesWithoutHint.length>0">
      <v-col cols="12">
        <v-card color="red lighten-5">
          <v-card-title class="subheading mx-1" @click="showMessages=!showMessages">

            <v-icon class="mr-1" small
                    color="red"
            >mdi-alert-outline
            </v-icon>
            <a style="font-weight: bolder; color: darkred;font-size:12px" title="Meldungen ein- bzw. ausblenden">Wartungs-
              und Störungsmeldungen der Katasterbehörden</a>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
              <v-btn class="mr-0 elevation-0"
                     icon
                     small

              >
                <v-icon v-if="!showMessages" small>mdi-arrow-expand</v-icon>
                <v-icon v-else small>mdi-arrow-collapse</v-icon>
              </v-btn>
              <span style="color: dimgrey;font-size:12px"
              >Meldungen <span
                  v-if="!showMessages">anzeigen</span><span v-else>ausblenden</span></span></v-col>
          </v-card-title>
          <v-list>
            <v-container class="pa-2">
              <v-row>
                <v-col cols="auto" class="pr-1 py-1 pb-1">
                  <v-img
                      :width="20"
                      :src="require('@/assets/stoerung.png')"
                  ></v-img>
                </v-col>
                <v-col cols="auto" style="font-size:12px" class="px-0 py-1 mr-16">Störung</v-col>
                <v-col cols="auto" class="pr-1 py-1">
                  <v-img
                      :width="20"
                      :src="require('@/assets/wartung.png')"
                  ></v-img>
                </v-col>
                <v-col cols="auto" style="font-size:12px" class="pa-0 py-1">Wartung</v-col>
                <v-spacer/>
              </v-row>

            </v-container>
          </v-list>
          <v-list dense class="pa-1" v-if="showMessages">
            <v-row v-for="(message,i) in messagesWithoutHint" :key="i" class="ma-1 mb-0">
              <v-col class="pa-0" cols="12"><span style="font-size: smaller"><template
                  v-if="message.properties.art==='Wartung'">{{ message.properties.created.substring(0, 10) }}</template>
                      <template
                          v-else>{{ message.properties.created }}<span
                          v-if="message.properties.updated"> (aktualisiert: {{
                          message.properties.updated
                        }})</span></template></span>
              </v-col>
              <v-col class="py-0" cols="12"><span
                  style="font-weight: bolder;font-size: 12px">{{ message.properties.name }}</span>
              </v-col>
              <v-col class="py-0" cols="12"><span
                  v-if="message.properties.art==='Wartung'"
                  style="color:blue"><strong>Wartung:</strong> <span v-html="message.properties.meldungstext"/> </span>
                <span
                    v-else-if="message.properties.art==='Störung'"
                    style="color:red"><strong>Störung:</strong> <span v-html="message.properties.meldungstext"/></span>
              </v-col>
            </v-row>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {API, visibility} from '@/config';
import MessagesITNRW from "@/components/MessagesITNRW";

export default {
  props: {
    typeOfAccess: {
      type: String,
      required: true,
    }
  },
  components: {
    MessagesITNRW
  },
  data() {
    return {
      visibility,
      messages: [],
      messagesITNRW: [],
      // showHint: ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name) ? false : true,
      showMessages: this.typeOfAccess === 'opendata',
    }
  },
  methods: {
    getMessages() {
      axios.post(`${API.url}${API.getMessages}`).then((response) => {
        this.messages = response.data.features;

        this.messagesITNRW = this.messages.filter(message => (message.properties.kn === '05000000' && ['allgemein',this.typeOfAccess].includes(message.properties.sichtbarkeit)));

        this.messages = this.messages.filter(message => (message.properties.kn !== '05000000' && message.properties.name!==null));
      }).catch(function () {
      })

    },
  },
  mounted() {
    this.getMessages();
  },
  computed: {
    messagesWithoutHint() {
      return this.messages.filter(message => (message.properties.art !== 'Information' && (message.properties.sichtbarkeit === this.typeOfAccess || message.properties.sichtbarkeit === 'allgemein')));
    },
  },
}
</script>